import React from 'react';

export default function Profile(props) {
  const { content } = props;
  return (
    <section id="profile" className="section">
      <div className="section-inner">
        <div className="content text-center">
          {content}
        </div>
      </div>
    </section>
  );
}
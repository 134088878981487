import React from 'react';

export default function Footer() {
  return (
    <footer className="footer">
      <div className="container text-center">
        <small className="copyright">Copyright &copy; {new Date().getFullYear()} RCG Associates Ltd<br />
          Company No. GB 8310911 &middot; VAT No. 209721611</small>
      </div>
    </footer>
  );
}
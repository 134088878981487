import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import LangSelector from './lang-selector';
import useModal from '../hooks/modal';

export default function Header(props) {

  const { onShow } = useModal('contact-form');
  const { company, langs, onLangChange } = props;
  const { name, title } = company;  

  return (
    <header className="header">
      <div className="container clearfix">
        <img className="profile-image img-fluid float-left" src="images/logo.png" alt={name} />
        <div className="profile-content float-left">
          <h1 className="name">{name}</h1>
          <h2 className="desc">{title}</h2>
        </div>
        <div className="button-container">
          <div className="icons language-links">
            <LangSelector langs={langs} onLangChange={onLangChange} displayCode={false} />
          </div>
          <button className="btn btn-primary btn-contact" onClick={onShow}>
            <FontAwesomeIcon icon={faPaperPlane} /> Contact Us
            </button>
        </div>
      </div>
    </header>
  );
}
import React from 'react';

function debounce(fn, ms) {
  let timer;
  return _ => {
    clearTimeout(timer);
    timer = setTimeout(_ => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

export default function ShowIf(props) {

  const [show, setShow] = React.useState(false);
  const { view, breakpoint } = props;

  const handleResize = () => {
    const isMobile = window.innerWidth < breakpoint;
    const show = (view === 'mobile' && isMobile) || (view === 'desktop' && !isMobile);
    setShow(show);
  };

  React.useEffect(() => {
    handleResize();
    const debouncedHandleResize = debounce(handleResize, 100);
    window.addEventListener('resize', debouncedHandleResize);
    return _ => { window.removeEventListener('resize', debouncedHandleResize) };
  });

  return (
    <React.Fragment>
      {show && props.children}
    </React.Fragment>
  );
}
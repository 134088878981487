import React, { useState } from 'react';
import Header from './components/header';
import Services from './components/services';
import ContactInfo from './components/contact-info';
import Testimonials from './components/testimonials';
import Technologies from './components/technologies';
import Footer from './components/footer';
import rcgEn from './data/rcg.json';
import MobileMenu from './components/mobile-menu';
import ContactFormModal from './components/contact-form-modal';
import About from './components/about';
import Overview from './components/overview';
import Profile from './components/profile';
import Promo from './components/promo';
import ShowIf from './show-if';

export default function App() {

  const breakpoint = 991.98; // Mobile breakpoint px
  const [langs] = useState([{ name: 'English', code: 'en' }]);
  const [content, setContent] = useState(rcgEn);

  const handleLangChange = (lang) => {
    switch (lang) {
      default:
        setContent(rcgEn);
    }
  }
  
  return (
    <div id="home" className="App">
      <div className="d-block d-lg-none">
        <MobileMenu langs={langs} onLangChange={handleLangChange} />
      </div>
      <Header langs={langs} company={content.company} onLangChange={handleLangChange} />
      <div className="container sections-wrapper">
        <div className="row">
          <div className="primary col-lg-8 col-12">
            <Profile content={content.company.description} />
            <Services services={content.services} />
            <ShowIf view="mobile" breakpoint={breakpoint}>
              <Overview />
              <Promo />
              <Technologies />
            </ShowIf>
            <ShowIf view="desktop" breakpoint={breakpoint}>
              <Promo />
              <Testimonials
                portfolio={content.portfolio}
                testimonials={content.testimonials} />
              <About about={content.about} />
            </ShowIf>
          </div>
          <div className="secondary col-lg-4 col-12">
            <ShowIf view="desktop" breakpoint={breakpoint}>
              <Overview />
              <Technologies />
            </ShowIf>
            <ShowIf view="mobile" breakpoint={breakpoint}>
              <Testimonials
                portfolio={content.portfolio}
                testimonials={content.testimonials} />
              <About about={content.about} />
            </ShowIf>
            <ContactInfo contact={content.contact} />
          </div>
        </div>
      </div>
      <Footer />
      <ContactFormModal contact={content.contact} />
    </div>
  );
}


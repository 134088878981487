import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBug } from '@fortawesome/free-solid-svg-icons';

export default function Technologies() {

  const items = [
    "HTML5, SCSS, JavaScript, Bootstrap",
    "React, Angular/AngularJS, TypeScript",
    "Responsive, mobile-friendly design",
    "Backend development in Microsoft .NET, Java or Node.js",
    "Client-managed websites built with WordPress",
    "Software architecture & design patterns",
    "Agile software development"
  ];

  const TechItems = () => (
    <ul>
      {items.map((item, i) => <li key={i}>{item}</li>)}
    </ul>
  );

  return (
    <>
      <section id="technologies" className="aside section">
        <div className="section-inner">
          <h2 className="heading">Technologies</h2>
          <div className="content">
            <p>Some of our favourite development technologies:</p>
            <TechItems />
            <p>
              We've been programming for long enough to have had plenty of experience with other languages, such as Java, PHP, Perl, C and C++.
              While we specialise in JavaScript frontend web applications and Microsoft C# .NET technologies, we can work with most technologies, languages and environments.
          </p>
          </div>
        </div>
      </section>
      <section id="technologies-promo" className="aside section">
        <div className="section-inner">
          <div className="content">
            <div className="d-flex align-items-center">
              <div className="bug-container">
                <FontAwesomeIcon icon={faBug} color="#ae2028" transform="rotate-30"></FontAwesomeIcon>
              </div>
              <h5 className="text-center">We can help you find and fix software bugs, bring projects back on track and improve performance of teams &amp; individuals</h5>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
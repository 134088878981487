import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export default function useModal(name) {
  const location = useLocation();
  const history = useHistory();
  const [show, setShow] = useState(false);

  const onHide = () => {
    history.push('/');
  };

  const onShow = () => {
    history.push(`#${name}`);
  };

  useEffect(() => {
    setShow(location.hash === `#${name}`);
  }, [location.hash, name]);

  return { show, onShow, onHide };
}

import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faMapMarkerAlt, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import LocationMapModal from './location-map-modal';
import EmailLink from './email-link';
import PhoneLink from './phone-link';
import useModal from '../hooks/modal';

export default function ContactInfo(props) {

  const { locations, email, mobile } = props.contact;
  const [mapSrc, setMapSrc] = useState('');
  const { onShow: onShowLocationMap } = useModal('location-map');
  const { onShow: onShowContact } = useModal('contact-form');

  const handleShowMap = (src) => {
    setMapSrc(src);
    onShowLocationMap();
  };

  return (
    <aside id="contact" className="info aside section">
      <div className="section-inner">
        <h2 className="heading">Contact Us</h2>
        <div className="content">
          <ul className="list-unstyled">
            <li>
              <FontAwesomeIcon icon={faEnvelope} fixedWidth />
              <span className="sr-only">Email:</span>
              <EmailLink email={email} />
            </li>
            <li>
              <FontAwesomeIcon icon={faPhone} fixedWidth />
              <span className="sr-only">Telephone:</span>
              <PhoneLink phone={mobile} />
            </li>
            <li>
              <div className="d-flex align-items-center">
                <div>
                  <FontAwesomeIcon icon={faMapMarkerAlt} fixedWidth />
                  <span className="sr-only">Location:</span>
                </div>
                <div>
                  {locations.map((loc, i) =>
                    <button className="d-block text-left href-link" key={i}
                      onClick={() => handleShowMap(loc.map)}>
                      {loc.name.map((t, i) => <span key={i}>{t}<br /></span>)}
                    </button>)}
                </div>
              </div>
            </li>
          </ul>
          <button className="btn btn-primary btn-contact" onClick={onShowContact}>
            <FontAwesomeIcon icon={faPaperPlane} /> Send message
              </button>
        </div>
      </div>
      <LocationMapModal src={mapSrc} />
    </aside>
  );
}

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare } from '@fortawesome/free-solid-svg-icons';

export default function ServiceItem(props) {

  const { title, description } = props.data;
  const { icon, iconLeft } = props;
  
  let transform = "shrink-8";
  if (iconLeft)
    transform += " left-" + iconLeft;
  
  return (
    <div className="item clearfix" style={{ marginBottom: '20px' }}>
      <span className="fa-layers fa-3x" style={{ float: 'left', marginRight: '5px' }}>
        <FontAwesomeIcon icon={faSquare} color="#ae2028" />
        <FontAwesomeIcon icon={icon} inverse transform={transform} />
        </span>
      <h5>{title}</h5>
      <div className="details">
        <p>{description}</p>
      </div>
    </div>
  );
}
import React from 'react';

export default function Promo() {
  return (
    <section className="section">
      <div className="section-inner pb-3">
        <div className="content text-center">
          <h5>
            We create robust, scalable web applications, compatible with all devices and screen sizes
          </h5>
          <p className="mb-2">
            From single or multi-page brochure websites, to full scale applications and database solutions
          </p>
        </div>
      </div>
    </section>
  );
}
import React from 'react';
import ServiceItem from './service-item';
import {
  faLaptopCode, faCode, faDatabase, faAddressCard, faUsers, faLaptopMedical,
  faWindowRestore, faSyncAlt
} from '@fortawesome/free-solid-svg-icons';

export default function Services(props) {
  const { services } = props;
  return (
    <section id="services" className="services section">
      <div className="section-inner">
        <h2 className="heading">Our Services</h2>
        <div className="content">
          <div className="row"> 
            <div className="col-sm-6"><ServiceItem data={services.webApps} icon={faLaptopCode} iconLeft="2" /></div>
            <div className="col-sm-6"><ServiceItem data={services.consultancy} icon={faUsers} iconLeft="2" /></div>
            <div className="col-sm-6"><ServiceItem data={services.devOnDemand} icon={faAddressCard} iconLeft="1" /></div>
            <div className="col-sm-6"><ServiceItem data={services.upgrades} icon={faSyncAlt} /></div>
            <div className="col-sm-6"><ServiceItem data={services.databases} icon={faDatabase} /></div>
            <div className="col-sm-6"><ServiceItem data={services.websites} icon={faWindowRestore} /></div>
            <div className="col-sm-6"><ServiceItem data={services.coding} icon={faCode} iconLeft="2" /></div>
            <div className="col-sm-6"><ServiceItem data={services.support} icon={faLaptopMedical} iconLeft="2" /></div>
          </div>
        </div>
      </div>
    </section>
  );
}
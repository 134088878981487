import React from 'react';

export default function About() {

  return (
    <section id="about" className="about section">
      <div className="section-inner">
        <h2 className="heading">About Us</h2>
        <div className="content">
          <img className="photo" src="/images/rg-profile.jpg" alt="Richard Gebbie" />
          <h6 className="font-weight-bold" style={{lineHeight: '1.5'}}>RCG Associates was founded in 2012 by Richard Gebbie</h6>
          <p>A software developer of 20 years’ commercial experience, Richard started his career as C++ developer for Sophos Anti-virus for Windows. In 2006, he joined Safenames, a tech start-up, as a senior software developer, and in 2009 as Head of Development. Richard led the development teams and built its products, services and backend systems.</p>
          <p>In 2012, Richard set up his own software development and consultancy RCG Associates Ltd, at the age of 30, which he runs from his home in Milton Keynes, with the assistance of his associates on large projects.</p>
          <p>He handles all aspects of web, software and database development, as well as IT and business consultancy. Richard works closely with marketing agencies and software houses, providing on-demand technical expertise and support.</p>
          <p>In 2004, Richard achieved a First Class honours degree in Software Engineering from Oxford Brookes University.</p>
          <p>He has worked for companies across the UK and in Switzerland, Spain and the USA.</p>
        </div>
      </div>
    </section>
  );
}
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Row, Col, Button } from 'react-bootstrap';
import useModal from '../hooks/modal';

export default function PortfolioModal(props) {
  
  const { show, onHide } = useModal('portfolio');
  const { portfolio } = props;

  return (
    <Modal show={show} onHide={onHide}
      dialogClassName="portfolio-modal modal-dialog-centered modal-dialog-scrollable">
      <Modal.Header>
        <Modal.Title>
          Portfolio
          <button className="close" onClick={onHide}><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          {portfolio.map((p, i) => (
            <Col md="6" key={i} className="mb-3">
              <h5 className="mb-1">
                <a className="text-body" rel="noopener noreferrer" target="_blank" href={p.url}>{p.title}
                  <span className="font-weight-normal"> ({p.url.replace('https://www.', '')})</span></a>
              </h5>
              <div className="d-flex align-items-top">
                <div className="portfolio-img">
                  <a rel="noopener noreferrer" target="_blank" href={p.url}
                    style={{ border: '1px solid black', margin: '3px 8px 0 0', display: 'block' }}>
                    <img src={`/images/clients/${p.image}`} width="90px" height="60px" alt="placeholder" />
                  </a>
                </div>
                <div className="portfolio-text">
                  {p.description}
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Modal.Body>
      <Modal.Footer className="border-0">
        <div className="mx-1">
          <Button variant="light" onClick={onHide}>Close</Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

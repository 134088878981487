import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons';

class TestimonialItem extends React.Component {
  render() {
    let t = this.props.testimonial;
    return (
      <div className="item">
        <blockquote className="quote">
          <p><FontAwesomeIcon icon={faQuoteLeft} />{t.message}</p>
        </blockquote>
        <div className="source d-flex align-items-center">
          <div style={{marginLeft: '20px'}}>
            <span className="name">{t.name}</span><br /><div className="title">{t.position},&nbsp;
            <a href={t.url} rel="noopener noreferrer" target="_blank">{t.company}</a></div>
          </div>
        </div>
      </div>
    );
  }
}

export default TestimonialItem;
import React from 'react';
import TestimonialItem from './testimonial-item';
import Portfolio from './portfolio-modal';
import useModal from '../hooks/modal';

export default function Testimonials(props) {

  const { testimonials, portfolio } = props;
  const { onShow } = useModal('portfolio');

  return (
    <aside id="clients" className="testimonials section">
      <div className="section-inner">
        <h2 className="heading">Our Clients</h2>
        <div className="content">
          <p>
            Most of our work is confidential or internal to the client, but we can show a small <a
              href="#portfolio" onClick={onShow}>portfolio of public websites</a>. We are proud to have long-standing service and support arrangements with our clients.
          </p>
          {testimonials.map((t, index) => <TestimonialItem key={index} testimonial={t} />)}
        </div>
      </div>
      <Portfolio portfolio={portfolio} />
    </aside>
  );
}

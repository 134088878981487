import React from 'react';

export default function Overview() {

  return (
    <aside id="overview" className="aside section">
      <div className="section-inner">
        <div className="content">
          
          <p>We work with digital &amp; marketing agencies, software houses, recruitment agencies and private entities, providing on-demand web development solutions and development expertise.</p>

          <h6>Software Houses &amp; Digital Agencies</h6>

          <p>We provide on-site or remote-based web &amp; software development services, from 1 day to several months. We can help solve a problem that can't be fixed in-house, provide an extra person to meet critical deadlines at short notice, or see through the development of an entire project lifecycle.
          </p>
          
          <h6>Private Companies &amp; Individuals</h6>
          <p>We have worked with companies across the UK, Europe and the USA. We can build your new applications or websites, or update and maintain your current architecture.
          </p>
          <p>We also provide expert IT and business consultancy to help grow your development teams, or solve any IT-related issues you're facing.
          </p>

          <h6>Recruitment Agencies</h6>
          <p>If your client is looking for a lead or senior web developer, consultant or development manager, please get in touch.
            Richard's CV for recruitment agencies is available on his <a href="https://www.richardgebbie.com" target="_blank" rel="noopener noreferrer" className="dotted-link"> personal website
            </a>.
          </p>
        </div>
      </div>
    </aside>
  );
}
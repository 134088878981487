import React, { useState, useRef, useEffect } from 'react';
import { slide as Menu } from 'react-burger-menu';
import { HashLink as Link } from 'react-router-hash-link';
import LangSelector from './lang-selector';
import { useLocation } from 'react-router-dom';

export default function MobileMenu(props) {

  const location = useLocation();
  const { langs, onLangChange } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [refs] = useState({
    home: useRef(null),
    services: useRef(null),
    overview: useRef(null),
    technologies: useRef(null),
    clients: useRef(null),
    about: useRef(null),
    contact: useRef(null)
  });

  const handleMenuClick = () => {
    setIsOpen(false);
  };

  const handleStateChange = (open) => {
    setIsOpen(open);
  };

  const handleLangChange = (lang) => {
    onLangChange(lang);
    handleStateChange(false);
  };

  useEffect(() => {
    if (!location.hash || location.hash.indexOf('#') !== 0) return;
    const hash = location.hash.substr(1);
    if (refs[hash]) refs[hash].current.click();
  }, [location.hash, refs]);

  return (
    <Menu right disableAutoFocus isOpen={isOpen} onStateChange={(s) => handleStateChange(s.isOpen)}>
          
      {/* Language selector */}
      <div className="menu-item">
        <div className="language-links">
          <LangSelector langs={langs} onLangChange={handleLangChange} displayCode={true} />
        </div>
      </div>

      {/* Anchor links */}
      <Link smooth to="#home" className="menu-item" innerRef={refs.home} onClick={handleMenuClick}>Home</Link>
      <Link smooth to="#services" className="menu-item" innerRef={refs.services} onClick={handleMenuClick}>Our Services</Link>
      <Link smooth to="#overview" className="menu-item" innerRef={refs.overview} onClick={handleMenuClick}>Who We Work With</Link>
      <Link smooth to="#technologies" className="menu-item" innerRef={refs.technologies} onClick={handleMenuClick}>Technologies</Link>
      <Link smooth to="#clients" className="menu-item" innerRef={refs.clients} onClick={handleMenuClick}>Our Clients</Link>
      <Link smooth to="#about" className="menu-item" innerRef={refs.about} onClick={handleMenuClick}>About Us</Link>
      <Link smooth to="#contact" className="menu-item" innerRef={refs.contact} onClick={handleMenuClick}>Contact Us</Link>
          
    </Menu>
  );
}

import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import axios from 'axios';
import PhoneLink from './phone-link';
import EmailLink from './email-link';
import useModal from '../hooks/modal';

export default function ContactFormModal(props) {

  const { mobile, email } = props.contact;
  const { register, handleSubmit, errors } = useForm();
  const [sent, setSent] = useState(false);
  const [sending, setSending] = useState(false);
  const [failed, setFailed] = useState(null);
  const { show, onHide } = useModal('contact-form');

  const inputNameClassNames = classNames('form-control', { 'input-error': errors.name })
  const inputEmailClassNames = classNames('form-control', { 'input-error': errors.email})
  const inputMessageClassNames = classNames('form-control', { 'input-error': errors.message })
    
  useEffect(() => {
    setSent(false);
    setSending(false);
    setFailed(false);
  }, [show]);

  const onSubmit = (data) => {
    setSending(true);
    axios.post('/php/sendmail.php?v=1.0', data).then((res) => {
      if (res && res.data === 'Success') {
        setSent(true);
      } else {
        setFailed("Unknown error: " + res.data);
      }
    }).catch((resp) => {
      setFailed(resp.message ? resp.message : resp.toString());
    });
  }

  return (
    <Modal show={show} dialogClassName="contact-modal modal-dialog-centered" onHide={onHide}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Modal.Header>
          <Modal.Title>
            Contact Us
          <button className="close" onClick={onHide}><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          {sent &&
            <div>
              <p className="mt-3 mb-4 text-center">Thank you.<br />We will be in touch as soon as possible.</p>
              <div className="text-center"><Button variant="light" onClick={onHide}>Close</Button></div>
            </div>
          }
          {!sent &&
            <>
              <p>Send a message using the form below and we will be in touch as soon as possible.
            Alternatively, call us any time on <PhoneLink phone={mobile} /> or email <EmailLink email={email} /></p>
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input name="name" id="name" type="name" maxLength="100" className={inputNameClassNames} ref={register({ required: true, maxLength: 100 })} />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email Address</label>
                <input name="email" id="email" type="email" maxLength="100" className={inputEmailClassNames} ref={register({ required: true, maxLength: 100 })} />
              </div>
              <div className="form-group">
                <label htmlFor="message">Message</label>
                <textarea name="message" id="message" maxLength="2000" rows="5" className={inputMessageClassNames} ref={register({ required: true, maxLength: 100 })} />
              </div>
            </>
          }
        </Modal.Body>
        <Modal.Footer className="border-0 pt-0">
          <div className="w-100 d-flex justify-content-between">
            <div className="w-50">
              {failed && <div className="error">Error: {failed}</div>}
            </div>
            <div className="text-right w-50">
              <Button variant="light" type="button" disabled={sending}
                className="mr-1"
                onClick={onHide}>Cancel</Button>
              <Button variant="primary" type="submit" disabled={sending}>Send</Button>
            </div>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

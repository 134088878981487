import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import useModal from '../hooks/modal';

export default function LocationMapModal(props) {

  const { src } = props;
  const { show, onHide } = useModal('location-map');

  return (
    <Modal show={show} dialogClassName="map-modal modal-dialog-centered" onHide={onHide}>
      <Modal.Body>
        <div className="map-container">
          <Spinner animation="border" role="status" variant="primary" size="lg">
            <span className="sr-only">Loading...</span>
          </Spinner>
          <iframe title="Map" src={src}
            width="100%" height="450" frameBorder="0" allowFullScreen=""></iframe>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="mx-1">
          <Button variant="light" onClick={onHide}>Hide Map</Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
